import { postRequest } from './index'
// 流程查询
export const processQuery = (data, successCallback, failureCallback) => {
  postRequest('/process/query', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 流程删除 新增  新改
export const processOperate = (data, successCallback, failureCallback) => {
  postRequest('/process/operate', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 流程删除 新增  新改
export const processCreate = (activitecode, successCallback, failureCallback) => {
  postRequest('/process/create?activitecode=' + activitecode, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 交易时间
// 交易时间列表
export const tradeTimeList = (data, successCallback, failureCallback) => {
  postRequest('/trade_time/list', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 交易时间新增修改
export const tradeTimeAdd = (data, successCallback, failureCallback) => {
  postRequest('/trade_time/add', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 交易日期列表
export const tradeDateList = (data, successCallback, failureCallback) => {
  postRequest('/trade_date/list', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 交易时间新增修改
export const tradeDateAdd = (data, successCallback, failureCallback) => {
  postRequest('/trade_date/add', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
