<template>
  <!-- 流程管理 -->
  <div class="workflowConfiguration">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      :form-item-arr="formItemArr"
    />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button size="small" type="primary" @click="editAndAddRow">
          添加
        </el-button>
      </div>
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
        :operation-width="230"
      />
      <!-- 分页 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
    <!-- 弹出的新增跟修改框 -->
    <Dialog
      ref="dialog"
      dialog-width="35%"
      :edit-form-data="editFormData"
      :form-item="addAndEditFormItem"
      @getFormData="getFormData"
    />
  </div>
</template>

<script>
import FormSearch from '../../components/FormSearch'
import Table from '../../components/Table'
import Pagination from '../../components/Pagination2'
import Dialog from '../../components/Dialog'
import { processQuery, processOperate, processCreate } from '../../api/basicSetting'
export default {
  components: { FormSearch, Table, Pagination, Dialog },
  data() {
    return {
      editFormData: {},
      itemData: [
        { label: '流程编号', prop: 'activitecode', width: 180 },
        { label: '流程类别', prop: 'activitetype', width: 180, child: this.$store.getters.getDictionaryItem('activitetype') },
        { label: '流程名称', prop: 'activitename', width: 180 },
        { label: '是否启用', prop: 'enable', width: 180, child: [{ label: '启用', value: '1' }, { label: '禁用', value: '0' }] }
      ],
      addAndEditFormItem: [
        { label: '流程编码', rules: 'none', type: 'input', value: 'activitecode', disabled: true },
        { label: '流程名称', type: 'input', value: 'activitename' },
        { type: 'select', label: '流程类别', value: 'activitetype', optionLabel: 'dictName', optionId: 'dictId', child: this.$store.getters.getDictionaryItem('activitetype') },
        { type: 'select', label: '是否启用', value: 'enable', optionLabel: 'label', optionId: 'value', child: [{ label: '启用', value: 1 }, { label: '禁用', value: 0 }] },
        { label: '流程说明', rules: 'none', type: 'input', value: 'activitedesc', textarea: true }
      ],
      operationButton: [
        { bType: 'primary', label: '编辑', handleEvent: this.editAndAddRow, withParameters: 'update' },
        { bType: 'danger', label: '删除', handleEvent: this.deleteRow, withParameters: 'delete' },
        { bType: 'primary', label: '定义流程', handleEvent: this.defineProcess }
      ],
      formItemArr: [
        { type: 'select', label: '流程类别', value: 'activitetype', pLabel: 'dictName', pValue: 'dictId', child: this.$store.getters.getDictionaryItem('activitetype') },
        { type: 'input', label: '流程名称', value: 'activitename' }
      ],
      loading: false,
      total: 0,
      listData: [],
      formInline: {
        pageSize: 10,
        pageNum: 1
      }
    }
  },
  methods: {
    // 点击定义流程
    defineProcess(row) {
      processCreate(row.activitecode, res => {
        const row = res.data
        this.$router.push({
          path: '/basicSetting/processMap',
          query: {
            activitecode: row.activitecode,
            activitedesc: row.activitedesc,
            activitename: row.activitename,
            activitetempid: row.activitetempid
          }
        })
      })
    },
    // 获取列表数据
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      processQuery(this.formInline, res => {
        this.listData = [...res.data.list]
        this.total = res.data.total
      })
    },
    // 新增或修改数据
    editAndAddRow(row, type) {
      this.editFormData = { ...row } || {}
      this.editFormData.pagetype = type || 'insert'
      this.$refs.dialog.editFormVisible = true
    },
    // 启用与禁用
    deleteRow(row) {
      row.pagetype = 'delete'
      this.$confirm('此操作将删除此条信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        processOperate(row, res => {
          this.$message.success('成功！')
          this.getdata(true)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 获取到输入框的内容
    getFormData(value) {
      processOperate(value, () => {
        this.getdata(value.id)
        this.$message.success('成功！')
        this.$refs.dialog.editFormVisible = false
      })
    }

  }
}
</script>

<style scoped lang="scss">
.workflowConfiguration {
  width: 100%;
}
</style>
